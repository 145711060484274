body {
    min-height: 100vh;
    max-width: 100vw;
  }
  
  p:not(:last-of-type) {
    margin-bottom: 1rem;
  }
  
  p a {
    color: rgb(82, 152, 255);
  }
  
  .header {
    width: 100%;
    padding: 3rem 4rem 0rem 4rem;
    display: flex;
    z-index: 101;
    justify-content: space-between;
    align-items: center;
  }
  
  .header--absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  
  .header__name {
    background-image: linear-gradient(
      330.4deg,
      rgb(68, 188, 240) 4.54%,
      rgb(114, 152, 248) 59.2%,
      rgb(160, 153, 255) 148.85%
    );
    background-size: 110%;
    background-clip: text;
    color: transparent;
    background-repeat: no-repeat;
  }
  
  .container {
    max-width: 50rem;
    padding: 2rem;
    margin: 0 auto;
  }
  
  .container--flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* min-height: 100vh; */
  }

  .container--small {
    margin: 0 auto;
    padding: 1rem;
  }
  
  .container--lg {
    max-width: 60rem;
  }
  
  .col {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 2rem 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  @media screen and (max-width: 44em) {
    .col {
      flex-direction: column;
    }
  }
  
  /* Thorin dialog on desktop */
  @media screen and (min-width: 640px) {
    .modal .modal {
      width: 35rem;
      max-width: 100%;
      justify-content: center;
    }
  }
  
  @media screen and (max-width: 34em) {
    h1 {
      font-size: 2.325rem !important;
    }
  
    .header {
      position: static;
      justify-content: center;
      padding: 2rem;
    }
  
    .container {
      min-height: unset;
    }
  
    .header__name {
      display: none;
    }
  
    .footer {
      position: static;
      padding: 2rem;
    }
  }
  
  @media screen and (max-height: 48em) and (max-width: 44em) {
    .header {
      position: static;
      padding: 2rem;
    }
  
    .container {
      min-height: unset;
    }
  
    .footer {
      position: static;
    }
  }
  